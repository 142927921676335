import React, { useContext } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { SiteContext } from '../../context/SiteContext';
import actionsYouCanTake from "../../assets/images/actionsyoucantake_landing.png";
import PrevNext from "../navigation/PrevNext";

function ActionsYouCanTakeLanding() {
    const { organ, language } = useContext(SiteContext);
    return (
        <>
            <div className="content two-column actions">
                <Row className="align-items-center column-reverse">
                    <Col xs={12} md={6}>
                        <div className="body-wrapper">
                            <h1>
                                {language.actionsYouCanTake.header}
                            </h1>
                            <div className="body">
                                {language.actionsYouCanTake.body}
                            </div>
                        </div>
                    </Col>
                    <Col xs={12} md={6} className="body-image">
                        <img src={actionsYouCanTake} alt={`${language.actionsYouCanTake.guideAltText}`} />
                    </Col>
                </Row>
            </div >
            <PrevNext previous={`/beingprepared/3?organ=${organ}`} next={`/actions/1?organ=${organ}`} />
        </>
    )
}

export default ActionsYouCanTakeLanding;