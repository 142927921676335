import React, { useContext, useEffect } from 'react';

import { languageOptions } from '../languages';
import { SiteContext } from '../context/SiteContext';

export default function LanguageSelector() {
  const { userLanguage, userLanguageChange } = useContext(SiteContext);

  // set selected language by calling context method
  const handleLanguageChange = e => userLanguageChange(e.target.value);

  useEffect(() => {
    let defaultLanguage = window.localStorage.getItem('ot-lang');
    if (!defaultLanguage) {
      defaultLanguage = window.navigator.language.substring(0, 2);
    }
    userLanguageChange(defaultLanguage);
  }, [userLanguageChange]);

  return (
    <div className="language-selector-wrapper">
      <select
        onChange={handleLanguageChange}
        value={userLanguage}
        className="form-control"
      >
        {Object.entries(languageOptions).map(([id, name]) => (
          <option key={id} value={id}>{name}</option>
        ))}
      </select>
    </div>
  );
};
