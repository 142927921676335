import React, { useState } from "react";
import MenuButton from "./MenuButton";
import Menu from "./Menu";
function Sidebar() {
    const [visible, setVisible] = useState(false);

    const toggle = () => {
        setVisible(!visible);
    }

    return (
        <nav aria-label="Main Navigation" className="side-nav">
            <MenuButton handleMouseDown={toggle} />
            <Menu handleMouseDown={toggle} menuVisibility={visible} />
        </nav>
    );
}

export default Sidebar;