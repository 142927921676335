import React, { useContext, useState } from "react";
import { SiteContext } from '../../context/SiteContext';
import PrevNext from "../navigation/PrevNext";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Modal from "react-bootstrap/Modal";
import LiverPDF from "../../assets/LiverSite_Plan_V12_3.pdf";
import KidneyPDF from "../../assets/Kidney_Offer_Plan_DPDC_English_6_20_23.pdf";

function ActionsYouCanTakeOne() {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const { organ, language } = useContext(SiteContext);

    const print = () =>{
        window.print();
    }
console.log(organ)
    let planUrl = LiverPDF;
    let organName = 'liver';
    if(organ === "ki")
    {
        planUrl = KidneyPDF;
        organName = 'kidney'
    }
    return (
        <>
            <div className="content one-column actions-you-can-take-one">
                <Row>
                    <Col xs={12} md={6}>
                        <div className="body-wrapper">
                            <div className="header-wrapper">
                                <div className="header patient">
                                    {language.actionsYouCanTake.pageOne.patientHeader}
                                </div>
                            </div>

                            <div className="body" dangerouslySetInnerHTML={{
                                __html:language.actionsYouCanTake.pageOne.patientBody.replace('{PLANURL}', planUrl).replace('{organ_name}', organName)
                            }}>
                            </div>
                        </div>

                    </Col>
                    <Col xs={12} md={6}>
                        <div className="body-wrapper">
                            <div className="header-wrapper">
                                <div className="header caregiver">
                                    {language.actionsYouCanTake.pageOne.caregiverHeader}
                                </div>
                            </div>
                            <div className="body" dangerouslySetInnerHTML={{
                                __html:language.actionsYouCanTake.pageOne.caregiverBody.replace('{PLANURL}', planUrl).replace('{organ_name}', organName)
                            }}>
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} className="links">
                        <div className="unos-link">
                            <a target="_blank" rel="noopener noreferrer" href="https://transplantliving.org/before-the-transplant/waiting-for-your-transplant/" className="btn btn-clear-blue">{language.actions.viewUnosChecklists}</a>
                            &nbsp;&nbsp;
                            <button onClick={print} className="btn btn-red">{language.actions.printActionList}</button>
                            &nbsp;&nbsp;
                            <a target="_blank" rel="noopener noreferrer" href={`${planUrl}`} className="btn btn-red">{language.actions.viewDonorOfferPlan.replace('{organ_name}', organName.charAt(0).toUpperCase() + organName.slice(1))}</a>
                        </div>
                    </Col>
                </Row>
            </div>
            <PrevNext previous={`/actions?organ=${organ}`} />
            <Modal
                show={show}
                onHide={handleClose}
                className="learn-more-modal"
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        <div className="title">
                            {language.actionsYouCanTake.pageOne.seeMoreTitle}
                        </div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body dangerouslySetInnerHTML={{
                    __html:language.actionsYouCanTake.pageOne.seeMoreBody
                }}>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default ActionsYouCanTakeOne;