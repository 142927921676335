import React from "react";
import {
  Switch,
  Route
} from "react-router-dom";
import TheCallOne from "../components/the-call/TheCallOne";
import TheCallTwo from "../components/the-call/TheCallTwo";
import TheCallThree from "../components/the-call/TheCallThree";
import TheCallFour from "../components/the-call/TheCallFour";
import TheCallLanding from "../components/the-call/TheCallLanding";

function TheCall() {
  return (
    <Switch>
      <Route path="/TheCall/1">
        <TheCallOne />
      </Route>
      <Route path="/TheCall/2">
        <TheCallTwo />
      </Route>
      <Route path="/TheCall/3">
        <TheCallThree />
      </Route>
      <Route path="/TheCall/4">
        <TheCallFour />
      </Route>
      <Route path="/TheCall">
        <TheCallLanding />
      </Route>
    </Switch>
  )
}

export default TheCall;