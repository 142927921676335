import React, { useContext, useState} from "react";
import { SiteContext } from '../../context/SiteContext';
import whatIsRightOne from "../../assets/images/whatisright_one.png";
import PrevNext from "../navigation/PrevNext";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

function WhatIsRightOne() {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const { organ, language } = useContext(SiteContext);
    return (
        <>
            <div className="content one-column what-is-right-one">
              
                <Row className="column-reverse">
                    <Col xs={12} className="body-image w-425" >
                        <img src={whatIsRightOne} alt={`${language.whatIsRight.pageOne.guideAltText}`} />
                    </Col>
                    <Col xs={12}>
                        <div className="body w-425" dangerouslySetInnerHTML={{
                            __html:language.whatIsRight.pageOne.body
                        }}>
                        </div>
                        <div className="what-is-right-buttons mt-4">
                            <Button variant="" className="btn-clear-blue" onClick={handleShow}>
                                {language.actions.seeMore}
                            </Button>
                        </div>
                    </Col>
                </Row>
            </div>
            
            <PrevNext previous={`/whatisright?organ=${organ}`} next={`/whatisright/2?organ=${organ}`} />
            <Modal
                show={show}
                onHide={handleClose}
                className="learn-more-modal-full"
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        <div className="title">
                            {language.whatIsRight.pageOne.seeMoreTitle}
                        </div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body dangerouslySetInnerHTML={{
                            __html:language.whatIsRight.pageOne.seeMoreBody
                        }}>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default WhatIsRightOne;