import React from "react";
import {
  Switch,
  Route
} from "react-router-dom";
import ActionsYouCanTakeOne from "../components/actions-you-can-take/ActionsYouCanTakeOne";
import ActionsYouCanTakeLanding from "../components/actions-you-can-take/ActionsYouCanTakeLanding";

function ActionsYouCanTake() {
  return (
    <Switch>
      <Route path="/actions/1">
        <ActionsYouCanTakeOne />
      </Route>
      <Route path="/actions">
        <ActionsYouCanTakeLanding />
      </Route>
    </Switch>
  )
}

export default ActionsYouCanTake;