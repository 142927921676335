import React, { useState, createContext} from "react";

import { languageOptions, languageList } from "../languages";

export const SiteContext = createContext({
  organ: "li",
  organName: "liver",
  userLanguage: "en",
  language: languageList.en
});

export function SiteProvider(props) {
  const organ = props.organ ? props.organ : "li";
  const organName = organ === "ki" ? "kidney" : "liver";
  const children = props.children;
  const [userLanguage, setUserLanguage] = useState("en");
  
  const provider = {
    organ,
    organName,
    userLanguage,
    language: languageList[userLanguage],
    userLanguageChange: selected => {
      const newLanguage = languageOptions[selected] ? selected : "en"
      setUserLanguage(newLanguage);
      window.localStorage.setItem("ot-lang", newLanguage);
    }
  };

  return (
    <SiteContext.Provider value={provider}>
      {children}
    </SiteContext.Provider>
  );
};
