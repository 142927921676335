import React, { useContext } from "react";
import { SiteContext } from '../../context/SiteContext';
import donorProfileOne from "../../assets/images/donorprofile_one.png";
import PrevNext from "../navigation/PrevNext";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col"
function DonorProfileOne() {
    const { organ, language } = useContext(SiteContext);
    return (
        <>
        <div className="content one-column">
           
            <Row>
                <Col xs={12}>
                    <div className="body w-600" dangerouslySetInnerHTML={{
                        __html:language.donorProfile.pageOne.body
                    }}>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col xs={12}>
                    <div className="body-image w-600">
                        <img src={donorProfileOne} alt={`${language.donorProfile.pageOne.guideAltText}`} />
                    </div>
                </Col>
            </Row></div>
            {organ === "li" && 
                <PrevNext previous={`/donorprofile?organ=${organ}`} next={`/donorprofile/2?organ=${organ}`} />
            }
            {organ === "ki" && 
                <PrevNext previous={`/donorprofile?organ=${organ}`} next={`/donorprofile/1k?organ=${organ}`} />
            }
        </>
    )
}

export default DonorProfileOne;