import React, { useContext } from "react";
import { SiteContext } from '../../context/SiteContext';
import whatIsRightThree from "../../assets/images/whatisright_three.png";
import PrevNext from "../navigation/PrevNext";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

function WhatIsRightThree() {
    const { organ, language } = useContext(SiteContext);
    return (
        <>
            <div className="content two-column what-is-right-three">

                <Row className="align-items-center column-reverse">
                    <Col xs={12} md={6}>
                        <div className="body-wrapper">
                            <div className="body" dangerouslySetInnerHTML={{
                                __html: language.whatIsRight.pageThree.body
                            }}>
                            </div>
                        </div>
                    </Col>
                    <Col xs={12} md={6} className="body-image" >
                        <img src={whatIsRightThree} alt={`${language.whatIsRight.pageThree.guideAltText}`} />
                    </Col>
                </Row></div>
            {organ === "li" &&
                <PrevNext previous={`/whatisright/2?organ=${organ}`} next={`/whatisright/4?organ=${organ}`} />
            }
            {organ === "ki" &&
                <PrevNext previous={`/whatisright/2k?organ=${organ}`} next={`/whatisright/4?organ=${organ}`} />
            }
        </>
    )
}

export default WhatIsRightThree;