import React, { useContext } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { SiteContext } from '../../context/SiteContext';
import donorPoolOne from "../../assets/images/donorpool_one.png";
import PrevNext from "../navigation/PrevNext";
function DonorPoolOne() {
    const { organ, language } = useContext(SiteContext);
    return (
        <>
            <div className="content one-column donor-pool-one">

                <Row>
                    <Col xs={12}>
                        <div className="body three-column">
                            <div className="text-block-wrapper first">
                                <div className="text-block">
                                    {language.donorPool.pageOne.textOne}
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col xs={12} className="body-image">
                        <img src={donorPoolOne} alt={`${language.donorPool.guideAltTextOne}`} />
                    </Col>
                </Row></div>
            <PrevNext previous={`/donorpool?organ=${organ}`} next={`/donorpool/2?organ=${organ}`} />
        </>
    )
}

export default DonorPoolOne;