import React, { useContext } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { SiteContext } from '../../context/SiteContext';
import donorPoolSix from "../../assets/images/donorpool_six.png";
import PrevNext from "../navigation/PrevNext";
function DonorPoolSix() {
    const { organ, language } = useContext(SiteContext);
    return (
        <>
            <div className="content one-column">
                <Row>
                    <Col xs={12}>
                        <div className="body w-640">
                            {language.donorPool.pageSix.body}
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <div className="body-image">
                            <img src={donorPoolSix} alt={`${language.donorPool.pageSix.guideAltText}`} />
                        </div>
                    </Col>
                </Row>
            </div>
            <PrevNext previous={`/donorpool/5?organ=${organ}`} next={`/donorprofile?organ=${organ}`} />
        </>
    )
}

export default DonorPoolSix;