import React, { useContext, useState } from "react";
import { SiteContext } from '../../context/SiteContext';
import beingPreparedThree from "../../assets/images/beingprepared_three.png";
import PrevNext from "../navigation/PrevNext";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

function BeingPreparedThree() {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const { organ, language } = useContext(SiteContext);
    return (
        <>
            <div className="content two-column being-prepared-three">
               
                <Row className="align-items-center column-reverse">
                    <Col xs={12} md={6}>
                        <div className="body-wrapper">
                            <div className="body" dangerouslySetInnerHTML={{
                                __html:language.beingPrepared.pageThree.body
                            }}>
                            </div>
                            <div className="start">
                            <Button variant="" className="btn-clear-blue" onClick={handleShow}>
                                {language.actions.learnMore}
                            </Button>
                        </div>
                        </div>
                    </Col>
                    <Col xs={12} md={6} className="body-image" >
                        <img src={beingPreparedThree} alt={`${language.beingPrepared.pageThree.guideAltText}`} />
                    </Col>
                </Row>
            </div>
            <PrevNext previous={`/beingprepared/2?organ=${organ}`} next={`/actions?organ=${organ}`} />
            <Modal
                show={show}
                onHide={handleClose}
                className="learn-more-modal-full"
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        {language.beingPrepared.pageThree.learnMoreHeader}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body dangerouslySetInnerHTML={{
                    __html:language.beingPrepared.pageThree.learnMoreBody
                }}>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default BeingPreparedThree;