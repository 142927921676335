import React, { useContext } from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { SiteContext } from '../../context/SiteContext';
import donorPool from "../../assets/images/donorpool_landing.png";
import PrevNext from "../navigation/PrevNext";

function DonorPoolLanding() {
    const { organ, language } = useContext(SiteContext);
    return (
        <>
            <div className="content two-column donor-pool-landing">
                <Row className="align-items-center column-reverse">
                    <Col xs={12} md={5}>
                        <div className="body-wrapper">
                            <h1>
                                {language.donorPool.header}
                            </h1>
                            <div className="body">
                                {language.donorPool.body}
                            </div>
                        </div>
                    </Col>
                    <Col xs={12} md={7} className="body-image">
                        <img src={donorPool} alt={`${language.donorPool.guideAltText}`} />
                    </Col>
                </Row>
            </div >
            <PrevNext previous={`/?organ=${organ}`} next={`donorpool/1?organ=${organ}`} />
        </>
    )
}

export default DonorPoolLanding;