import React, { useContext } from "react";
import { SiteContext } from '../../context/SiteContext';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col"
import donorMatchTwo from "../../assets/images/donormatch_two.png";
import PrevNext from "../navigation/PrevNext";

function DonorMatchTwo() {
    const { organ, language } = useContext(SiteContext);
    return (
        <>
        <div className="content one-column donor-match">
            
            <Row>
                <Col xs={12}>
                    <div className="body w-525">
                        {language.donorMatch.pageTwo.body}
                    </div>
                </Col>
            </Row>
            <Row>
                <Col xs={12}>
                    <div className="body-image w-525">
                        <img src={donorMatchTwo} alt={`${language.donorMatch.pageTwo.guideAltText}`} />
                    </div>
                </Col>
            </Row></div>
            <PrevNext previous={`/donormatch/1?organ=${organ}`} next={`/donormatch/3?organ=${organ}`} />
        </>
    )
}

export default DonorMatchTwo;