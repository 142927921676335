import React, { useContext } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { SiteContext } from '../../context/SiteContext';
import donorPoolFive from "../../assets/images/donorpool_five.png";
import PrevNext from "../navigation/PrevNext";
function DonorPoolFive() {
    const { organ, language } = useContext(SiteContext);
    return (
        <>
            <div className="content one-column">
                <Row>
                    <Col xs={12}>
                        <div className="body w-500" dangerouslySetInnerHTML={{
                            __html:language.donorPool.pageFive.body
                        }}>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <div className="body-image">
                            <img src={donorPoolFive} alt={`${language.donorPool.pageFive.guideAltText}`} />
                        </div>
                    </Col>
                </Row></div>
            <PrevNext previous={`/donorpool/4?organ=${organ}`} next={`/donorpool/6?organ=${organ}`} />
        </>
    )
}

export default DonorPoolFive;
