import React, { useContext, useEffect, useState, useRef } from "react";
import { SiteContext } from '../context/SiteContext';
import Alert from "react-bootstrap/Alert";
import DonorPool from "../screens/DonorPool"
import DonorProfile from "../screens/DonorProfile"
import DonorMatch from "../screens/DonorMatch"
import TheCall from "../screens/TheCall";
import WhatIsRight from "../screens/WhatIsRight";
import BeingPrepared from "../screens/BeingPrepared";
import ActionsYouCanTake from "../screens/ActionsYouCanTake";
import Home from "../screens/Home";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import NoMatch from "../screens/NoMatch";

import Header from "../components/header/Header";

import {
  Switch,
  Route
} from "react-router-dom";
export default function Main() {

  const { language } = useContext(SiteContext);
  const [showAlert, setShowAlert] = useState(true);
  const [alertHeight, setAlertHeight] = useState(null);
  const ref = useRef(null);

  useEffect(() => {
    setAlertHeight(ref.current.clientHeight);
    window.addEventListener('resize', handleResize);
  }, [])

  const handleResize = () => {
    setAlertHeight(ref.current.clientHeight);
  }

  const handleMailToClick = () => {
    window.location = 'mailto:' + language.home.bannerMailTo;
  }

  return (
    <main>
      <Alert 
        ref={ref} 
        onClose={() => setShowAlert(false)} 
        style={showAlert ? null : {display: 'none'}}
        dismissible>
        {language.home.banner}
        <Button className="link mail-to" onClick={handleMailToClick} variant="link">
          {language.home.bannerMailTo}
        </Button>.
      </Alert>
      <Container style={showAlert ? {paddingTop: alertHeight} : {paddingTop: '0'}}>
        <Switch>
          <Route path="/donorpool">
            <Header section="donorPool" />
            <DonorPool />
          </Route>
          <Route path="/donorprofile">
          <Header section="donorProfile" />
            <DonorProfile />
          </Route>
          <Route path="/donormatch">
          <Header section="donorMatch" />
            <DonorMatch />
          </Route>
          <Route path="/thecall">
          <Header section="theCall" />
            <TheCall />
          </Route>
          <Route path="/whatisright">
          <Header section="whatIsRight" />
            <WhatIsRight />
          </Route>
          <Route path="/beingprepared">
          <Header section="beingPrepared" />
            <BeingPrepared />
          </Route>
          <Route path="/actions">
          <Header section="actionsYouCanTake" />
            <ActionsYouCanTake />
          </Route>
          <Route exact path="/">
          <Header section="home" />
            <Home />
          </Route>
          <Route>
            <NoMatch />
          </Route>

        </Switch>
      </Container>
    </main>
  );
}
