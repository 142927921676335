import React, { useContext} from "react";
import { SiteContext } from '../../context/SiteContext';
import theCallThree from "../../assets/images/thecall_three.png";
import PrevNext from "../navigation/PrevNext";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

function TheCallThree() {
    const { organ, language } = useContext(SiteContext);

    const organName = (organ === 'ki' ? 'kidney' : 'liver')
  
    return (
        <>
        <div className="content two-column the-call-three">
           
            <Row className="align-items-center">
                <Col xs={12} md={6}>
                    <div className="body-wrapper">
                        <div className="body" dangerouslySetInnerHTML={{
                            __html:language.theCall.pageThree.body.replace('{organ_name}', organName)
                        }}>
                        </div>
                    </div>
                </Col>
                <Col xs={12} md={6} className="body-image" >
                        <img src={theCallThree} alt={`${language.theCall.pageThree.guideAltText}`} />
                </Col>
            </Row>
            <Row>
                <Col xs={12}>
                   
                </Col>
            </Row>
        </div>
         <PrevNext previous={`/thecall/2?organ=${organ}`} next={`/thecall/4?organ=${organ}`} />
         </>
    )
}

export default TheCallThree;