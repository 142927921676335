import React, { useContext } from "react";
import { SiteContext } from '../../context/SiteContext';
import beingPreparedTwo from "../../assets/images/beingprepared_two.png";
import PrevNext from "../navigation/PrevNext";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

function BeingPreparedTwo() {
    const { organ, language } = useContext(SiteContext);
    return (
        <>
            <div className="content one-column being-prepared-two">
                <Row className="column-reverse">
                    <Col xs={12}>
                        <div className="body w-640" dangerouslySetInnerHTML={{
                            __html:language.beingPrepared.pageTwo.body
                        }}>
                        </div>
                    </Col>
                    <Col xs={12} className="body-image w-640" >
                        <img src={beingPreparedTwo} alt={`${language.beingPrepared.pageTwo.guideAltText}`} />
                    </Col>

                </Row>
            </div>
            <PrevNext previous={`/beingprepared/1?organ=${organ}`} next={`/beingprepared/3?organ=${organ}`} />
        </>
    )
}

export default BeingPreparedTwo;