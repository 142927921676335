import React, { useContext, useState, useRef } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { SiteContext } from '../../context/SiteContext';
import donorProfileFive from "../../assets/images/donorprofile_five.png";
import PrevNext from "../navigation/PrevNext";

function DonorPoolFive() {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const { organ, organName, language } = useContext(SiteContext);
    const ref = useRef(null);
    return (
        <>
            <div className="content two-column donor-profile-five">

                <Row className="align-items-center column-reverse" >
                    <Col xs={12} md={6}>
                        <div className="body-wrapper">
                            <div className="body" dangerouslySetInnerHTML={{
                                __html:language.donorProfile.pageFive.body.replace("{ORGAN}", organName)
                            }}>
                            </div>
                            <div className="start">
                                <Button variant="" className="btn-clear-blue" onClick={handleShow}>
                                    {language.actions.learnMore}
                                </Button>
                            </div>
                        </div>
                    </Col>
                    <Col xs={12} md={6} className="body-image" >
                        <div className="learn-more" ref={ref}>
                            <Modal
                                show={show}
                                onHide={handleClose}
                                container={ref.current}
                                className="learn-more-modal"
                            >
                                <Modal.Header closeButton>
                                    <Modal.Title>
                                        {language.donorProfile.pageFive.learnMoreHeader.replace("{ORGAN}", organName)}
                                    </Modal.Title>
                                </Modal.Header>
                                <Modal.Body dangerouslySetInnerHTML={{
                                    __html:language.donorProfile.pageFive.learnMoreBody
                                }}>


                                </Modal.Body>
                            </Modal>
                            <img src={donorProfileFive} alt={`${language.donorProfile.pageFive.guideAltText}`} />
                        </div>
                    </Col>
                </Row>
                
            </div >
            <PrevNext previous={`/donorprofile/4?organ=${organ}`} next={`/donorprofile/6?organ=${organ}`} />
        </>
    )
}

export default DonorPoolFive;