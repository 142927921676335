import React, { useContext } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { SiteContext } from '../../context/SiteContext';
import donorPoolThree from "../../assets/images/donorpool_three.png";
import PrevNext from "../navigation/PrevNext";
function DonorPoolThree() {
    const { organ, language } = useContext(SiteContext);
    return (
        <>
            <div className="content one-column donor-pool-three">

                <Row>
                    <Col xs={12}>
                        <div className="body three-column">
                            <div className="text-block-wrapper first">
                                <div className="text-block">
                                    {language.donorPool.pageThree.textOne}
                                </div>
                            </div>
                            <div className="text-block-wrapper second">
                                <div className="text-block" dangerouslySetInnerHTML={{
                                    __html:language.donorPool.pageThree.textTwo
                                }}>
                                </div>
                            </div>
                            <div className="text-block-wrapper third">
                                <div className="text-block" dangerouslySetInnerHTML={{
                                    __html:language.donorPool.pageThree.textThree
                                }}>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col xs={12} className="body-image">
                        <img src={donorPoolThree} alt={`${language.donorPool.guideAltTextThree}`} />
                    </Col>
                </Row></div>
            <PrevNext previous={`/donorpool/2?organ=${organ}`} next={`/donorpool/4?organ=${organ}`} />
        </>
    )
}

export default DonorPoolThree;