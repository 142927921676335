import React from "react";
import './styles/App.scss';
import CacheBuster from 'react-cache-buster';
import { version } from '../package.json';
import { SiteProvider } from './context/SiteContext';
import Sidebar from "./components/navigation/Sidebar";
import Main from "./components/Main";
import {
  useLocation
} from "react-router-dom";

function App() {
  let landingPages = ["/", "/donorpool", "/donorprofile", "/donormatch", "/thecall", "/whatisright", "/beingprepared", "/actions"];
  let location = useLocation();
  let bodyCSS = "interior-page"
  if (landingPages.includes(location.pathname)) {
    bodyCSS = "landing-page"
  }

  const search = location.search; 
  const params = new URLSearchParams(search);
  const selectedOrgan = params.get('organ');
  const isProduction = process.env.NODE_ENV === 'production'

  return (
    <CacheBuster
      currentVersion={version}
      isEnabled={isProduction}
      isVerboseMode={false}
    >
      <div className={`${bodyCSS}`}>
        <SiteProvider organ={selectedOrgan}>
          <Sidebar />
          <Main />
        </SiteProvider>
      </div>
    </CacheBuster>
  );
}

export default App;