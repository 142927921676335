import React, { useContext, useRef } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { SiteContext } from '../../context/SiteContext';
import donorProfileOneK from "../../assets/images/donorprofile_onek.png";
import PrevNext from "../navigation/PrevNext";

function DonorProfileOneK() {
    const { organ, language } = useContext(SiteContext);
    const ref = useRef(null);
    return (
        <>
            <div className="content two-column donor-profile-four">

                <Row className="align-items-center">
                    <Col xs={12} md={6}>
                        <div className="body-wrapper">
                            <div className="body" dangerouslySetInnerHTML={{
                                __html:language.donorProfile.pageOneK.body
                            }}>
                            </div>
                        </div>
                    </Col>
                    <Col xs={12} md={6} className="body-image" >
                        <div className="learn-more" ref={ref}>
                            <img src={donorProfileOneK} alt={`${language.donorProfile.pageOneK.guideAltText}`} />
                        </div>
                    </Col>
                </Row>
                
            </div >
            <PrevNext previous={`/donorprofile/1?organ=${organ}`} next={`/donorprofile/2?organ=${organ}`} />
        </>
    )
}

export default DonorProfileOneK;