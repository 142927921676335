import React, { useContext } from "react";
import { SiteContext } from '../../context/SiteContext';
import whatIsRightTwo from "../../assets/images/whatisright_two.png";
import PrevNext from "../navigation/PrevNext";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

function WhatIsRightTwo() {
    const { organ, language } = useContext(SiteContext);
    return (
        <>
            <div className="content two-column what-is-right-two">

                <Row className="column-reverse">
                    <Col xs={12} md={6}>
                        <div className="body-wrapper">
                        {organ === "li" &&
                            <div className="body" dangerouslySetInnerHTML={{
                                __html: language.whatIsRight.pageTwo.body
                            }}>
                            </div>
                        }
                        {organ === "ki" &&
                            <div className="body" dangerouslySetInnerHTML={{
                                __html: language.whatIsRight.pageTwoK.body
                            }}>
                            </div>
                        }
                        </div>
                    </Col>
                    <Col xs={12} md={6} className="body-image" >
                        <img src={whatIsRightTwo} alt={`${language.whatIsRight.pageTwo.guideAltText}`} />
                    </Col>
                </Row></div>
            {organ === "li" &&
                <PrevNext previous={`/whatisright/1?organ=${organ}`} next={`/whatisright/3?organ=${organ}`} />
            }
            {organ === "ki" &&
                <PrevNext previous={`/whatisright/1?organ=${organ}`} next={`/whatisright/2k?organ=${organ}`} />
            }
        </>
    )
}

export default WhatIsRightTwo;