import React, { useContext, useState } from "react";
import { SiteContext } from '../../context/SiteContext';
import theCallOne from "../../assets/images/thecall_one.png";
import PrevNext from "../navigation/PrevNext";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import MedicalTerms from "../medical-terms/MedicalTerms";

function TheCallOne() {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const { organ, language } = useContext(SiteContext);
    return (
        <>
            <div className="content two-column the-call-one">

                <Row className="column-reverse">
                    <Col xs={12} md={6}>
                        <div className="body-wrapper">
                            <div className="body" dangerouslySetInnerHTML={{
                                __html:language.theCall.pageOne.body
                            }}>
                            </div>
                            <div className="start">
                                <Button variant="" className="btn-clear-blue" onClick={handleShow}>
                                    {language.actions.viewMedicalTerms}
                                </Button>
                            </div>
                        </div>
                    </Col>
                    <Col xs={12} md={6} className="body-image" >
                        <div className="medical-terms">
                            <Modal
                                show={show}
                                onHide={handleClose}
                                className="medical-terms-modal"
                            >
                                <Modal.Header closeButton>
                                    <Modal.Title>
                                        <div className="title">
                                            {language.medicalTerms.title}
                                        </div>
                                        <div className="sub-title">
                                            {language.medicalTerms.subTitle}
                                        </div>
                                    </Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <MedicalTerms terms={organ === "ki" ? language.medicalTerms.termsKidney : language.medicalTerms.terms} />
                                </Modal.Body>
                            </Modal>
                            <img src={theCallOne} alt={`${language.theCall.pageOne.guideAltText}`} />
                        </div>
                    </Col>
                </Row></div>
            <PrevNext previous={`/donormatch/3?organ=${organ}`} next={`/thecall/2?organ=${organ}`} />
        </>
    )
}

export default TheCallOne;