import React, { useContext, useState } from "react";
import { SiteContext } from '../../context/SiteContext';
import beingPreparedOne from "../../assets/images/beingprepared_one.png";
import PrevNext from "../navigation/PrevNext";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Modal from "react-bootstrap/Modal";

function BeingPreparedOne() {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const { organ, language } = useContext(SiteContext);
    return (
        <>
            <div className="content one-column being-prepared-one">
                <Row className="column-reverse">
                    <Col xs={12}>
                        <div className="body w-625" dangerouslySetInnerHTML={{
                            __html:language.beingPrepared.pageOne.body
                        }}>
                        </div>
                    </Col>
                    <Col xs={12} className="body-image w-600" >
                        <img src={beingPreparedOne} alt={`${language.beingPrepared.pageOne.guideAltText}`} />
                    </Col>
                </Row>
            </div>
            <PrevNext previous={`/beingprepared?organ=${organ}`} next={`/beingprepared/2?organ=${organ}`} />
            <Modal
                show={show}
                onHide={handleClose}
                className="learn-more-modal"
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        <div className="title">
                            {language.beingPrepared.pageOne.seeMoreTitle}
                        </div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body dangerouslySetInnerHTML={{
                    __html:language.beingPrepared.pageOne.seeMoreBody
                }}>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default BeingPreparedOne;