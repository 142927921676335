import React, {useContext} from "react";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { SiteContext } from '../../context/SiteContext';
import arrowPng from "../../assets/images/arrow.png";
import LanguageSelector from "../LanguageSelector"
function Menu(props) {
  const location = useLocation();
  const { organ, organName, language } = useContext(SiteContext);
  let arrow = <img src={arrowPng} alt="Active link" className="arrow" />
  var visibility = "hide";

  if (props.menuVisibility) {
    visibility = "show";
  }

  return (
    <div id="flyoutMenu"

      className={visibility}>
      <Row>
        <Col>
          <div className="top-row">
            <div className="section-title">
              {organName} Organ<br />
              Offer Guide
            </div>
            <div className="close" onMouseDown={props.handleMouseDown}>
              <i className="fas fa-times"></i>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="links">
            <h2>
              {location.pathname.startsWith("/donorpool") ? <>{arrow} {language.donorPool.header}</> : <Link to={`/donorpool?organ=${organ}`} onClick={props.handleMouseDown}>{language.donorPool.header}</Link>}
            </h2>
            <h2>
              {location.pathname.startsWith("/donorprofile") ? <>{arrow} {language.donorProfile.header}</> : <Link to={`/donorprofile?organ=${organ}`} onClick={props.handleMouseDown}>{language.donorProfile.header}</Link>}
            </h2>
            <h2>
              {location.pathname.startsWith("/donormatch") ? <>{arrow} {language.donorMatch.header}</> : <Link to={`/donormatch?organ=${organ}`} onClick={props.handleMouseDown}>{language.donorMatch.header}</Link>}
            </h2>
            <h2>
              {location.pathname.startsWith("/thecall") ? <>{arrow} {language.theCall.header}</> : <Link to={`/thecall?organ=${organ}`} onClick={props.handleMouseDown}>{language.theCall.header}</Link>}
            </h2>
            <h2>
              {location.pathname.startsWith("/whatisright") ? <>{arrow} {language.whatIsRight.header}</> : <Link to={`/whatisright?organ=${organ}`} onClick={props.handleMouseDown}>{language.whatIsRight.header}</Link>}
            </h2>
            <h2>
              {location.pathname.startsWith("/beingprepared") ? <>{arrow} {language.beingPrepared.header}</> : <Link to={`/beingprepared?organ=${organ}`} onClick={props.handleMouseDown}>{language.beingPrepared.header}</Link>}
            </h2>
            <h2>
              {location.pathname.startsWith("/actions") ? <>{arrow} {language.actionsYouCanTake.header}</> : <Link to={`/actions?organ=${organ}`} onClick={props.handleMouseDown}>{language.actionsYouCanTake.header}</Link>}
            </h2>
          </div>
          <div>
            <LanguageSelector />
          </div>
        </Col>
      </Row>
      <Row>
        <Col xs={12} className="home-footer">
          <Link to={`/?organ=${organ}`} onClick={props.handleMouseDown} className="btn btn-clear-green">
            {language.home.home}
          </Link>&nbsp;
          <Link to={`/?organ=${organ}`} onClick={props.handleMouseDown} className="btn btn-clear-green">
            {language.actions.support}
          </Link>
          <div className="more-information">
            {language.actions.moreInformation}
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default Menu;