import React from "react";
import hamburger from "../../assets/images/hamburger.png";
function MenuButton(props){
    return (
        <button onMouseDown={props.handleMouseDown} className="toggle spacing">
            <img src={hamburger} alt="Menu" />
         </button>
      );
}

export default MenuButton;