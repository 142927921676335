import React from "react";
import {
  Switch,
  Route
} from "react-router-dom";
import DonorProfileOne from "../components/donor-profile/DonorProfileOne";
import DonorProfileOneK from "../components/donor-profile/DonorProfileOneK";
import DonorProfileTwo from "../components/donor-profile/DonorProfileTwo";
import DonorProfileThree from "../components/donor-profile/DonorProfileThree";
import DonorProfileFour from "../components/donor-profile/DonorProfileFour";
import DonorProfileFive from "../components/donor-profile/DonorProfileFive";
import DonorProfileSix from "../components/donor-profile/DonorProfileSix";
import DonorProfileLanding from "../components/donor-profile/DonorProfileLanding";

function DonorProfile() {
  return (
    <Switch>
      <Route path="/DonorProfile/1">
        <DonorProfileOne />
      </Route>
      <Route path="/DonorProfile/1k">
        <DonorProfileOneK />
      </Route>
      <Route path="/DonorProfile/2">
        <DonorProfileTwo />
      </Route>
      <Route path="/DonorProfile/3">
        <DonorProfileThree />
      </Route>
      <Route path="/DonorProfile/4">
        <DonorProfileFour />
      </Route>
      <Route path="/DonorProfile/5">
        <DonorProfileFive />
      </Route>
      <Route path="/DonorProfile/6">
        <DonorProfileSix />
      </Route>
      <Route path="/DonorProfile">
        <DonorProfileLanding />
      </Route>
    </Switch>
  )
}

export default DonorProfile;