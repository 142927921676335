import React, { useContext, useRef, useState } from "react";
import { SiteContext } from '../../context/SiteContext';
import whatIsRightTwo from "../../assets/images/whatisright_twok.png";
import PrevNext from "../navigation/PrevNext";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
function WhatIsRightTwoK() {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const { organ, language } = useContext(SiteContext);
    const ref = useRef(null);
    return (
        <>
            <div className="content two-column what-is-right-two">

                <Row className="column-reverse">
                    <Col xs={12} md={6}>
                        <div className="body-wrapper">
                            <div className="body" dangerouslySetInnerHTML={{
                                __html: language.whatIsRight.pageTwoOneK.body
                            }}>
                            </div>
                            <div className="start pt-3">
                                <Button variant="" className="btn-clear-blue" onClick={handleShow}>
                                    {language.actions.learnMore}
                                </Button>
                            </div>
                        </div>
                    </Col>
                    <Col xs={12} md={6} className="body-image" >
                        <div className="learn-more" ref={ref}>

                            <img src={whatIsRightTwo} alt={`${language.whatIsRight.pageTwoK.guideAltText}`} />
                        </div>

                    </Col>
                </Row></div>
            <PrevNext previous={`/whatisright/2?organ=${organ}`} next={`/whatisright/3?organ=${organ}`} />
            <Modal
                show={show}
                onHide={handleClose}

                className=""
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        {language.whatIsRight.pageTwoOneK.learnMoreHeader}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body dangerouslySetInnerHTML={{
                    __html: language.whatIsRight.pageTwoOneK.learnMoreBody
                }}>


                </Modal.Body>
            </Modal>
        </>
    )
}

export default WhatIsRightTwoK;