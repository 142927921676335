import React, { useContext, useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Accordion from "react-bootstrap/Accordion";
import AccordionContext from 'react-bootstrap/AccordionContext';
import Card from "react-bootstrap/Card";
import { SiteContext } from '../../context/SiteContext';
import PrevNext from "../navigation/PrevNext";
import poster from '../../assets/images/poster.png'
import { useAccordionToggle } from 'react-bootstrap/AccordionToggle';
import MedicalTerms from "../medical-terms/MedicalTerms";
import GeneralKidneyOffer from "../../assets/videos/GeneralKidneyOffer.mp4";
import GeneralLiverOffer from "../../assets/videos/GeneralLiverOffer.mp4";
import HepCOfferKidney from "../../assets/videos/HepCOfferKidney.mp4";
import HepCOfferLiver from "../../assets/videos/HepCOfferLiver.mp4";
import RiskCriteriaOfferKidney from "../../assets/videos/RiskCriteriaOfferKidney.mp4";
import RiskCriteriaOfferLiver from "../../assets/videos/RiskCriteriaOfferLiver.mp4";


function TheCallFour() {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const { organ, language } = useContext(SiteContext);
    const [eventKey, setEventKey] = useState("0");

    const handleCallback = (eventKey) => {
        setEventKey(eventKey);
    }

    let video = null;
    let generalDonorVideo = null;//"https://organ-tool.s3.us-east-2.amazonaws.com/general_liver_offer.mp4";
    let phsIncreasedRiskVideo = null;//"https://organ-tool.s3.us-east-2.amazonaws.com/risk_criteria_offer.mp4";
    let hepatitisCNATVideo = null;//"https://organ-tool.s3.us-east-2.amazonaws.com/hep_c_offer.mp4";

    if(organ === "li")
    {
        generalDonorVideo = GeneralLiverOffer;
        phsIncreasedRiskVideo = RiskCriteriaOfferLiver;
        hepatitisCNATVideo = HepCOfferLiver;
    
    }
    else
    {
        generalDonorVideo = GeneralKidneyOffer;
        phsIncreasedRiskVideo = RiskCriteriaOfferKidney;
        hepatitisCNATVideo = HepCOfferKidney;
    }
    if (eventKey === "0") {
        video = generalDonorVideo
    }
    else if (eventKey === "1") {
        video = phsIncreasedRiskVideo
    }
    else {
        video = hepatitisCNATVideo;
    }

    return (
        <>
            <div className="content two-column the-call-four">
                
                <Row >
                    <Col xs={12}>
                        <div className="header">
                            {language.theCall.pageFour.header}
                        </div>
                        <div className="sub-header">
                        {language.theCall.pageFour.subHeader}
                        </div>
                    </Col>
                    <Col xs={12} md={4}>
                        <div className="body-wrapper pl-0">
                            <div className="body">
                                <Accordion defaultActiveKey="0" className="accordion">
                                    <Card>
                                        <Card.Header>
                                            <ContextAwareToggle eventKey="0" callback={handleCallback}>
                                                {language.theCall.pageFour.generalDonor}
                                            </ContextAwareToggle>
                                        </Card.Header>
                                        <Accordion.Collapse eventKey="0">
                                            <Card.Body>
                                                <div className="text">
                                                    {language.theCall.pageFour.generalDonorBody}
                                                </div>
                                                <div className="video">
                                                    <video
                                                        src={generalDonorVideo}
                                                        controls={true}
                                                        width="100%"
                                                        poster={poster}
                                                        className="player-wrapper"
                                                    />
                                                </div>
                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                    <Card>
                                        <Card.Header>
                                            <ContextAwareToggle eventKey="1" callback={handleCallback}>
                                                {language.theCall.pageFour.phsIncreasedRisk}
                                            </ContextAwareToggle>
                                        </Card.Header>
                                        <Accordion.Collapse eventKey="1">
                                            <Card.Body>
                                                <div className="text">
                                                    {language.theCall.pageFour.phsIncreasedRiskBody}
                                                </div>
                                                <div className="video">
                                                    <video
                                                        src={phsIncreasedRiskVideo}
                                                        controls={true}
                                                        width="100%"
                                                        poster={poster}
                                                        className="player-wrapper"
                                                    />
                                                </div>
                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                    <Card>
                                        <Card.Header>
                                            <ContextAwareToggle eventKey="2" callback={handleCallback}>
                                                {language.theCall.pageFour.hepatitisCNAT}
                                            </ContextAwareToggle>
                                        </Card.Header>
                                        <Accordion.Collapse eventKey="2">
                                            <Card.Body>
                                                <div className="text">
                                                    {language.theCall.pageFour.hepatitisCNATBody}
                                                </div>
                                                <div className="video">
                                                    <video
                                                        src={hepatitisCNATVideo}
                                                        controls={true}
                                                        width="100%"
                                                        poster={poster}
                                                        className="player-wrapper"
                                                    />
                                                </div>
                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                </Accordion>
                            </div>

                        </div>
                    </Col>
                    <Col xs={12} md={8} className="body-image" >
                        <video
                            src={video}
                            controls={true}
                            width="100%"
                            poster={poster}
                            className="player-wrapper"
                        />
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <div className="the-call-buttons">
                            <Button className="btn-clear-blue" onClick={handleShow}>
                                {language.actions.viewMedicalTerms}
                            </Button>
                        </div>
                    </Col>
                </Row>
            </div >
            <PrevNext previous={`/thecall/3?organ=${organ}`} next={`/whatisright?organ=${organ}`} />
            <Modal
                show={show}
                onHide={handleClose}
                className="medical-terms-modal"
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        <div className="title">
                            {language.medicalTerms.title}
                        </div>
                        <div className="sub-title">
                            {language.medicalTerms.subTitle}
                        </div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <MedicalTerms terms={organ === "ki" ? language.medicalTerms.termsKidney : language.medicalTerms.terms} />
                </Modal.Body>
            </Modal>
        </>
    )
}

function ContextAwareToggle({ children, eventKey, callback }) {

    const currentEventKey = useContext(AccordionContext);

    const onClick = useAccordionToggle(
        eventKey,
        () => callback && callback(eventKey),
    );

    const isCurrentEventKey = currentEventKey === eventKey;

    return (
        <button
            type="button"
            className={isCurrentEventKey ? 'btn active' : 'btn'}
            onClick={onClick}
        >
            {children}
        </button>
    );
}

export default TheCallFour;