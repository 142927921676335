import React from "react";
import { Link } from "react-router-dom";
import right from "../../assets/images/arrows-style-right.png";
import left from "../../assets/images/arrows-style-left.png";
function PrevNext(props) {
    return (
        <div className="prev-next-container">
            {props.previous &&
                <Link to={props.previous}>
                    <img src={left} alt="Previous page" />
                </Link>
            }
            {props.next &&
                <Link to={props.next}>
                    <img src={right} alt="Next page" /> 
                </Link>
            }
        </div>
    );
}

export default PrevNext;