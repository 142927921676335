import React, { useContext } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { SiteContext } from '../../context/SiteContext';
import beingPrepared from "../../assets/images/beingprepared_landing.png";
import PrevNext from "../navigation/PrevNext";

function BeingPreparedLanding() {
    const { organ, language } = useContext(SiteContext);
    return (
        <>
            <div className="content two-column being-prepared-landing">
                <Row className="align-items-center column-reverse">
                    <Col xs={12} md={6}>
                        <div className="body-wrapper">
                            <h1>
                                {language.beingPrepared.header}
                            </h1>
                            <div className="body">
                                {language.beingPrepared.body}
                            </div>
                        </div>
                    </Col>
                    <Col xs={12} md={6} className="body-image">
                        <img src={beingPrepared} alt={`${language.beingPrepared.guideAltText}`} />
                    </Col>
                </Row>
                
            </div >
            <PrevNext previous={organ === "ki" ? `/whatisright/4?organ=${organ}` : `/whatisright/5?organ=${organ}`} next={`/beingprepared/1?organ=${organ}`} />
        </>
    )
}

export default BeingPreparedLanding;