import React, { useContext } from "react";
import MobileNav from "../navigation/MobileNav";
import { SiteContext } from "../../context/SiteContext";
function Header(props) {
    const { language } = useContext(SiteContext);
    return (
        <div className="title-container">
        <MobileNav />
        <div className="section-title">
            {language[props.section].header}
        </div>
        </div>
    )
}

export default Header;