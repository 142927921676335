import React from "react";
import {
  Switch,
  Route
} from "react-router-dom";
import DonorPoolOne from "../components/donor-pool/DonorPoolOne";
import DonorPoolTwo from "../components/donor-pool/DonorPoolTwo";
import DonorPoolThree from "../components/donor-pool/DonorPoolThree";
import DonorPoolFour from "../components/donor-pool/DonorPoolFour";
import DonorPoolFive from "../components/donor-pool/DonorPoolFive";
import DonorPoolSix from "../components/donor-pool/DonorPoolSix";
import DonorPoolLanding from "../components/donor-pool/DonorPoolLanding";


function DonorPool() {
  return (
    <Switch>
      <Route path="/donorpool/1">
        <DonorPoolOne />
      </Route>
      <Route path="/donorpool/2">
        <DonorPoolTwo />
      </Route>
      <Route path="/donorpool/3">
        <DonorPoolThree />
      </Route>
      <Route path="/donorpool/4">
        <DonorPoolFour />
      </Route>
      <Route path="/donorpool/5">
        <DonorPoolFive />
      </Route>
      <Route path="/donorpool/6">
        <DonorPoolSix />
      </Route>
      <Route path="/donorpool">
        <DonorPoolLanding />
      </Route>
    </Switch>
  )
}

export default DonorPool;