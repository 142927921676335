import React, { useContext } from "react";
import { Link } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { SiteContext } from '../context/SiteContext';

function NoMatch() {
    const { organ, language } = useContext(SiteContext);
    return (
        <div className="content one-column no-match">
            
            <Row>
                <Col xs={12}>
                    <div className="body-wrapper text-center">
                    <h1>
                        {language.noMatch.header}
                    </h1>
                    <div className="body mb-3">
                        {language.noMatch.body}
                    </div>
                    <div className="start">
                        <Link to={`/?organ=${organ}`} className="btn btn-red">
                            {language.noMatch.goHome}
                        </Link>
                    </div>
                    </div>
                </Col>
            </Row>
        </div>
    )
}

export default NoMatch;