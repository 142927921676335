import React, { useContext } from "react";
import { SiteContext } from '../../context/SiteContext';
import theCallTwo from "../../assets/images/thecall_two.png";
import PrevNext from "../navigation/PrevNext";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

function TheCallTwo() {
    const { organ, language } = useContext(SiteContext);
    return (
        <>
            <div className="content two-column the-call-two">

                <Row className="align-items-center column-reverse">
                    <Col xs={12} md={6}>
                        <div className="body-wrapper">
                            <div className="body" dangerouslySetInnerHTML={{
                                __html:language.theCall.pageTwo.body
                            }}>
                            </div>
                        </div>
                    </Col>
                    <Col xs={12} md={6} className="body-image" >
                        <img src={theCallTwo} alt={`${language.theCall.pageTwo.guideAltText}`} />
                    </Col>
                </Row></div>
            <PrevNext previous={`/thecall/1?organ=${organ}`} next={`/thecall/3?organ=${organ}`} />
        </>
    )
}

export default TheCallTwo;