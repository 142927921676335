import React, { useContext } from "react";
import { SiteContext } from '../../context/SiteContext';
import PrevNext from "../navigation/PrevNext";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Calculator from "./waitlist-calculator/Calculator";
import WhatIsRightFour from "./WhatIsRightFour";

function WhatIsRightFive() {
    const { organ } = useContext(SiteContext);
    return (
        <>
            {
                organ === "ki" ? <WhatIsRightFour /> :
                <>
                    <div className="content what-is-right-five">
                        <Row >
                            <Col>
                                <Calculator/>
                            </Col>
                        </Row>
                    </div>
                    <PrevNext previous={`/whatisright/4?organ=${organ}`} next={`/beingprepared?organ=${organ}`} />
                </>
            }
        </>
    )
}

export default WhatIsRightFive;