import React, { useContext, useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { SiteContext } from '../../context/SiteContext';
import donorProfileSix from "../../assets/images/donorprofile_six.png";
import PrevNext from "../navigation/PrevNext";

function DonorPoolSix() {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const { organ, language } = useContext(SiteContext);
    return (
        <>
            <div className="content two-column donor-profile-six">

                <Row className="align-items-center" >
                    <Col xs={12} md={6}>
                        <div className="body-wrapper">
                            <div className="body" dangerouslySetInnerHTML={{
                                __html:language.donorProfile.pageSix.body
                            }}>
                            </div>
                            <div className="start pt-3">
                                <Button variant="" className="btn-clear-blue" onClick={handleShow}>
                                    {language.actions.learnMore}
                                </Button>
                            </div>
                        </div>
                    </Col>
                    <Col xs={12} md={6} className="body-image" >
                        <div className="learn-more">
                            <Modal
                                show={show}
                                onHide={handleClose}
                                className="learn-more-modal-full"
                            >
                                <Modal.Header closeButton>
                                    <Modal.Title>
                                        {language.donorProfile.pageSix.learnMoreHeader}
                                    </Modal.Title>
                                </Modal.Header>
                                <Modal.Body dangerouslySetInnerHTML={{
                                    __html:language.donorProfile.pageSix.learnMoreBody
                                }}>


                                </Modal.Body>
                            </Modal>
                            <img src={donorProfileSix} alt={`${language.donorProfile.pageSix.guideAltText}`} />
                        </div>
                    </Col>
                </Row>
                
            </div >
            <PrevNext previous={`/donorprofile/5?organ=${organ}`} next={`/donormatch?organ=${organ}`} />
        </>
    )
}

export default DonorPoolSix;