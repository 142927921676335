import React, { useContext } from "react";
import { SiteContext } from '../../context/SiteContext';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col"
import donorProfileTwo from "../../assets/images/donorprofile_two.png";
import PrevNext from "../navigation/PrevNext";

function DonorPoolTwo() {
    const { organ, language } = useContext(SiteContext);
    return (
        <>
            <div className="content one-column donor-profile">
                <Row>
                    <Col xs={12}>
                        <div className="body w-640">
                            {language.donorProfile.pageTwo.body}
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <div className="body-image lg">
                            <img src={donorProfileTwo} alt={`${language.donorProfile.pageTwo.guideAltText}`} />
                        </div>
                    </Col>
                </Row>
            </div>
            {organ === "ki" &&
                <PrevNext previous={`/donorprofile/1k?organ=${organ}`} next={`/donorprofile/3?organ=${organ}`} />
            }
            {organ === "li" &&
                <PrevNext previous={`/donorprofile/1?organ=${organ}`} next={`/donorprofile/3?organ=${organ}`} />
            }
        </>
    )
}

export default DonorPoolTwo;