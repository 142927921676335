import React, { useContext } from "react";
import { Link } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { SiteContext } from '../context/SiteContext';
import donorPool from "../assets/images/donorpool.png";
import donorProfile from "../assets/images/donorprofile.png";
import donorMatch from "../assets/images/donormatch.png";
import theCall from "../assets/images/thecall.png";
import practice from "../assets/images/practice.png";
import whatIsRight from "../assets/images/whatisright.png";
import beingPrepared from "../assets/images/beingprepared.png";
import seeInformation from "../assets/images/seeinfo.png";
import actions from "../assets/images/actions.png";

function Home() {
    const { organ, organName, language } = useContext(SiteContext);
  
    return (
        <div className="content two-column home">
            <Row className="column-reverse">
                <Col xs={12} md={6} >
                    <div className="body-wrapper">
                        <h1>
                            {language.home.header}
                        </h1>
                        <div className="body">
                            {language.home.body.replace("{ORGAN}", organName)}
                        </div>
                        <div className="start">
                            <Link to={`/donorpool?organ=${organ}`} className="btn btn-red">
                                {language.actions.start}
                            </Link>
                        </div>
                    </div>
                </Col>
                <Col xs={12} md={6} className="body-image" >
                    <Row>
                        <Col xs={4} className="home-box-image">
                            <Link
                                to={`/donorpool?organ=${organ}`}>
                                <img src={donorPool} alt="Donor Pool" />
                            </Link>
                        </Col>
                        <Col xs={4} className="home-box-image">
                            <Link
                                to={`/donorprofile?organ=${organ}`}>
                                <img src={donorProfile} alt="Donor Profile"/>
                            </Link>
                        </Col>
                        <Col xs={4} className="home-box-image">
                            <Link
                                to={`/donormatch?organ=${organ}`}>
                                <img src={donorMatch} alt="Donor Match"/>
                            </Link>
                        </Col>
                        <Col xs={4} className="home-box-image">
                            <Link
                                to={`/thecall?organ=${organ}`}>
                                <img src={theCall} alt="The Call" />
                            </Link>
                        </Col>
                        <Col xs={4} className="home-box-image">
                            <Link
                                to={`/thecall/4?organ=${organ}`}>
                                <img src={practice} alt="Practice what an offer call is like"/>
                            </Link>
                        </Col>
                        <Col xs={4} className="home-box-image">
                            <Link
                                to={`/whatisright?organ=${organ}`}>
                                <img src={whatIsRight} alt="What is right for you" />
                            </Link>
                        </Col>
                        <Col xs={4} className="home-box-image">
                            <Link
                                to={`/beingprepared?organ=${organ}`}>
                                <img src={beingPrepared} alt="Being prepared for an offer"/>
                            </Link>
                        </Col>
                        <Col xs={4} className="home-box-image">
                            <Link
                                to={`/whatisright/4?organ=${organ}`}>
                                <img src={seeInformation} alt="See information on personalized risks"/>
                            </Link>
                        </Col>
                        <Col xs={4} className="home-box-image">
                            <Link
                                to={`/actions?organ=${organ}`}>
                                <img src={actions} alt="Actions you can take"/>
                            </Link>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row>
                <Col xs={12} className="home-footer">
                    <Link to="/support" className="btn btn-clear-green">
                        {language.actions.support}
                    </Link>
                    <div className="more-information">
                        {language.actions.moreInformation}
                    </div>
                </Col>
            </Row>
        </div>
    )
}

export default Home;