import React from "react";
import {
  Switch,
  Route
} from "react-router-dom";
import DonorMatchOne from "../components/donor-match/DonorMatchOne";
import DonorMatchTwo from "../components/donor-match/DonorMatchTwo";
import DonorMatchThree from "../components/donor-match/DonorMatchThree";
import DonorMatchLanding from "../components/donor-match/DonorMatchLanding";

function DonorMatch() {
  return (
    <Switch>
      <Route path="/DonorMatch/1">
        <DonorMatchOne />
      </Route>
      <Route path="/DonorMatch/2">
        <DonorMatchTwo />
      </Route>
      <Route path="/DonorMatch/3">
        <DonorMatchThree />
      </Route>
      <Route path="/DonorMatch">
        <DonorMatchLanding />
      </Route>
    </Switch>
  )
}

export default DonorMatch;