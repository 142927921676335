import React, { useContext, useState, useRef } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { SiteContext } from '../../context/SiteContext';
import donorMatchFive from "../../assets/images/donormatch_three.png";
import PrevNext from "../navigation/PrevNext";

function DonorMatchThree() {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const { organ, language } = useContext(SiteContext);
    const ref = useRef(null);
    return (
        <>
            <div className="content two-column donor-match-three">

                <Row className="align-items-center  column-reverse">
                    <Col xs={12} md={6}>
                        <div className="body-wrapper">
                            {organ === "li" &&
                                <>
                                    <div className="body" dangerouslySetInnerHTML={{
                                        __html: language.donorMatch.pageThree.body
                                    }}>
                                    </div>
                                    <div className="start pt-3">
                                        <Button variant="" className="btn-clear-blue" onClick={handleShow}>
                                            {language.actions.learnMore}
                                        </Button>
                                    </div>
                                </>
                            }
                            {organ === "ki" &&
                                <div className="body" dangerouslySetInnerHTML={{
                                    __html: language.donorMatch.pageThree.bodyKidney
                                }}>
                                </div>
                            }
                        </div>
                    </Col>
                    <Col xs={12} md={6} className="body-image" >
                        <div className="learn-more" ref={ref}>
                            <Modal
                                show={show}
                                onHide={handleClose}
                                container={ref.current}
                                className="learn-more-modal"
                            >
                                <Modal.Header closeButton>
                                    <Modal.Title>
                                        {language.donorMatch.pageThree.learnMoreHeader}
                                    </Modal.Title>
                                </Modal.Header>
                                <Modal.Body dangerouslySetInnerHTML={{
                                    __html: language.donorMatch.pageThree.learnMoreBody
                                }}>


                                </Modal.Body>
                            </Modal>
                            <img src={donorMatchFive} alt={`${language.donorMatch.pageThree.guideAltText}`} />
                        </div>
                    </Col>
                </Row>

            </div >
            <PrevNext previous={`/donormatch/2?organ=${organ}`} next={`/thecall?organ=${organ}`} />
        </>

    )
}

export default DonorMatchThree;