import React, { useContext } from "react";
import { SiteContext } from '../../context/SiteContext';
import donorMatchOne from "../../assets/images/donormatch_one.png";
import PrevNext from "../navigation/PrevNext";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col"
function DonorMatchOne() {
    const { organ, language } = useContext(SiteContext);
    return (
        <>
            <div className="content one-column">
                <Row>
                    <Col xs={12}>
                        <div className="body w-500">
                        {organ === "ki" &&
                                language.donorMatch.pageOne.bodyKidney
                            }
                            {organ === "li" &&
                                language.donorMatch.pageOne.bodyKidney
                            }
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <div className="body-image w-500">
                            <img src={donorMatchOne} alt={`${language.donorMatch.pageOne.guideAltText}`} />
                        </div>
                    </Col>
                </Row></div>
            <PrevNext previous={`/donormatch?organ=${organ}`} next={`/donormatch/2?organ=${organ}`} />
        </>
    )
}

export default DonorMatchOne;