import React, { useContext } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { SiteContext } from '../../context/SiteContext';
import donorProfile from "../../assets/images/donorprofile_landing.png";
import PrevNext from "../navigation/PrevNext";

function DonorProfileLanding() {
    const { organ, language } = useContext(SiteContext);
    return (
        <>
        <div className="content two-column donor-profile">
            
            <Row className="align-items-center column-reverse">
                <Col xs={12} md={6}>
                    <div className="body-wrapper">
                        <h1>
                            {language.donorProfile.header}
                        </h1>
                        <div className="body">
                            {language.donorProfile.body}
                        </div>
                    </div>
                </Col>
                <Col xs={12} md={6} className="body-image">
                    <img src={donorProfile} alt={`${language.donorProfile.guideAltText}`} />
                </Col>
            </Row>
        </div >
        <PrevNext previous={`/donorpool/6?organ=${organ}`} next={`donorprofile/1?organ=${organ}`} />
        </>
    )
}

export default DonorProfileLanding;