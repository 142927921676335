import React, { useContext } from "react";
import { SiteContext } from '../../context/SiteContext';
import whatIsRightFour from "../../assets/images/whatisright_four.png";
import whatIsRightFourK from "../../assets/images/whatisright_fourk.png";
import PrevNext from "../navigation/PrevNext";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

function WhatIsRightFour() {
    const { organ, language } = useContext(SiteContext);
    const percent = organ === "li" ? "fourth" : "fifth";
    return (
    <>
        <div className="content two-column what-is-right-four">

            <Row className="align-items-center column-reverse">
                <Col xs={12} md={6}>
                    <div className="body-wrapper">
                        <div className="body" dangerouslySetInnerHTML={ organ === "ki" ? {
                            __html:language.whatIsRight.pageFour.bodyK.replace("{PERCENT}", percent)
                        } : {
                            __html:language.whatIsRight.pageFour.bodyL.replace("{PERCENT}", percent)
                        }}>
                        </div>
                    </div>
                </Col>
                <Col xs={12} md={6} className="body-image" >
                    <img src={organ === "ki" ? whatIsRightFourK : whatIsRightFour} alt={`${language.whatIsRight.pageFour.guideAltText}`} />
                </Col>
            </Row>
            <Row>
                <Col xs={12}>

                </Col>
            </Row>
        </div>
        <PrevNext previous={`/whatisright/3?organ=${organ}`} next={organ === "ki" ? `/beingprepared?organ=${organ}` : `/whatisright/5?organ=${organ}`} />
    </>
    )
}

export default WhatIsRightFour;