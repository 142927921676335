import React from "react";

function DataError(props) {
    if (props.error) {
        console.log("dataerror");
        return (
            <div className="data-error">
                Unable to get data
            </div>
        )
    }

    return null;
}

export default DataError;