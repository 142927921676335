import React, {useContext} from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { SiteContext } from '../../context/SiteContext';

function MedicalTerms(props) {
    const { language } = useContext(SiteContext);
    let terms = props.terms;
    if (terms) {
        return (
            <>
            <Row>
                {terms.map(function (term) {
                    return (
                        <Col xs={12} md={6}>
                            <div className="term">
                                <div className="title">
                                    {term.title}
                                </div>
                                <div className="description">
                                    {term.description}
                                </div>
                            </div>
                        </Col>
                    );
                })}
            </Row>
            <Row>
                <Col>
                    <div className="unos-link">
                        <a href="https://optn.transplant.hrsa.gov/resources/glossary/" target="_blank" rel="noopener noreferrer" className="btn btn-clear-blue">{language.actions.viewMoreTerms}</a>
                    </div>
                </Col>
            </Row>
            </>
            
        );
    }

    return "Could not find medical terms";
}

export default MedicalTerms;