import React from "react";
import {
  Switch,
  Route
} from "react-router-dom";
import BeingPreparedOne from "../components/being-prepared/BeingPreparedOne";
import BeingPreparedTwo from "../components/being-prepared/BeingPreparedTwo";
import BeingPreparedThree from "../components/being-prepared/BeingPreparedThree";
import BeingPreparedLanding from "../components/being-prepared/BeingPreparedLanding";

function BeingPrepared() {
  return (
    <Switch>
      <Route path="/BeingPrepared/1">
        <BeingPreparedOne />
      </Route>
      <Route path="/BeingPrepared/2">
        <BeingPreparedTwo />
      </Route>
      <Route path="/BeingPrepared/3">
        <BeingPreparedThree />
      </Route>
      <Route path="/BeingPrepared">
        <BeingPreparedLanding />
      </Route>
    </Switch>
  )
}

export default BeingPrepared;