import React from "react";
import Spinner from "react-bootstrap/Spinner";

function Loading(props) {
    if (props.loading) {
        return (
            <div className="spinner-container">
                <div className="spinner-wrapper center">
                    <Spinner animation="border" role="status">
                        <span className="sr-only">Loading...</span>
                    </Spinner>
                    <div className="text center">
                        Updating results...
                    </div>
                </div>
            </div>
        )
    }

    return null;
}

export default Loading;