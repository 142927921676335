import React, { useContext } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { SiteContext } from '../../context/SiteContext';
import donorMatch from "../../assets/images/donormatch_landing.png";
import PrevNext from "../navigation/PrevNext";

function DonorMatchLanding() {
    const { organ, language } = useContext(SiteContext);
    return (
        <>
            <div className="content two-column donor-match">

                <Row className="align-items-center column-reverse">
                    <Col xs={12} md={6}>
                        <div className="body-wrapper">
                            <h1>
                                {language.donorMatch.header}
                            </h1>
                            <div className="body">
                                {organ === "ki" &&
                                    language.donorMatch.bodyKidney
                                }
                                {organ === "li" &&
                                    language.donorMatch.body
                                }
                            </div>
                        </div>
                    </Col>
                    <Col xs={12} md={6} className="body-image">
                        <img src={donorMatch} alt={`${language.donorMatch.guideAltText}`} />
                    </Col>
                </Row>
            </div >
            <PrevNext previous={`/donorprofile/6?organ=${organ}`} next={`/donormatch/1?organ=${organ}`} />
        </>
    )
}

export default DonorMatchLanding;