import React, { useContext } from "react";
import { SiteContext } from '../../context/SiteContext';
import donorProfileThree from "../../assets/images/donorprofile_three.png";
import PrevNext from "../navigation/PrevNext";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col"

function DonorPoolThree() {
    const { organ, language } = useContext(SiteContext);
    return (
        <>
        <div className="content one-column">
           
            <Row>
                <Col xs={12}>
                <div className="body w-500" dangerouslySetInnerHTML={{
                        __html:language.donorProfile.pageThree.body
                    }}>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col xs={12}>
                    <div className="body-image w-500">
                        <img src={donorProfileThree} alt={`${language.donorProfile.pageThree.guideAltText}`}/>
                    </div>
                </Col>
            </Row></div>
            <PrevNext previous={`/donorprofile/2?organ=${organ}`} next={`/donorprofile/4?organ=${organ}`} />
        </>
    )
}

export default DonorPoolThree;