import React from "react";
import {
  Switch,
  Route
} from "react-router-dom";
import WhatIsRightOne from "../components/what-is-right-for-you/WhatIsRightOne";
import WhatIsRightTwo from "../components/what-is-right-for-you/WhatIsRightTwo";
import WhatIsRightTwoK from "../components/what-is-right-for-you/WhatIsRightTwoK";
import WhatIsRightThree from "../components/what-is-right-for-you/WhatIsRightThree";
import WhatIsRightFour from "../components/what-is-right-for-you/WhatIsRightFour";
import WhatIsRightFive from "../components/what-is-right-for-you/WhatIsRightFive";
import WhatIsRightLanding from "../components/what-is-right-for-you/WhatIsRightLanding";

function WhatIsRight() {
  return (
    <Switch>
      <Route path="/WhatIsRight/1">
        <WhatIsRightOne />
      </Route>
      <Route path="/WhatIsRight/2">
        <WhatIsRightTwo />
      </Route>
      <Route path="/WhatIsRight/2k">
        <WhatIsRightTwoK />
      </Route>
      <Route path="/WhatIsRight/3">
        <WhatIsRightThree />
      </Route>
      <Route path="/WhatIsRight/4">
        <WhatIsRightFour />
      </Route>
      <Route path="/WhatIsRight/5">
        <WhatIsRightFive />
      </Route>
      <Route path="/WhatIsRight">
        <WhatIsRightLanding />
      </Route>
    </Switch>
  )
}

export default WhatIsRight;