import React, { useState, useEffect } from 'react';
import { useContext } from "react";
import { SiteContext } from '../../../context/SiteContext';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Button from 'react-bootstrap/Button';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Modal from "react-bootstrap/Modal";
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import ReactMarkdown from 'react-markdown';
import Loading from "./Loading";
import DataError from "./DataError";
import exportIcon from "../../../assets/images/export_square_green.jpg"
import ErrorFallback from "./ErrorFallback";
import { ErrorBoundary } from 'react-error-boundary';
import OptionsPopupText from "../../../docs/OptionsMarkdown.md";
import GeneralInfoPopupText from '../../../docs/GeneralInfoMarkdown.md';

require('highcharts/modules/exporting')(Highcharts);
require('highcharts/modules/export-data')(Highcharts);
require("highcharts/modules/accessibility")(Highcharts);

function Calculator() {
  const { language } = useContext(SiteContext);
  const bodyColor = "#212529";
  const [hasDataError, setHasDataError] = useState(false);
  const [stateIsSelected, setStateIsSelected] = useState(false);
  const [chartData, setChartData] = useState([]);
  const [statesData, setStatesData] = useState(null);
  const [allCenterData, setAllCenterData] = useState(null);
  const [stateCenterData, setStateCenterData] = useState(null);
  const [meldData, setMeldData] = useState(null);
  const [bloodTypeData, setBloodTypeData] = useState(null);
  const [ageData, setAgeData] = useState(null);
  const [exceptionData, setExceptionData] = useState(null);
  const [selectedState, setSelectedState] = useState(language.whatIsRight.pageFive.stateDropdownDefault);
  const [selectedCenter, setSelectedCenter] = useState("");
  const [selectedMeld, setSelectedMeld] = useState("");
  const [selectedBloodType, setSelectedBloodType] = useState("");
  const [selectedAge, setSelectedAge] = useState("");
  const [selectedException, setSelectedException] = useState("");
  const [series, setSeries] = useState([]);
  const [resultsText, setResultsText] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [dateInfo, setDateInfo] = useState("");
  const [categories, setCategories] = useState(null);
  const [categoriesN, setCategoriesN] = useState(1);
  const [showOptionsPopup, setShowOptionsPopup] = useState(false);
  const [optionsPopupText, setOptionsPopupText] = useState("");
  const [showGeneralInfoPopup, setShowGeneralInfoPopup] = useState(false);
  const [generalInfoPopupText, setGeneralInfoPopupText] = useState("");
  const [hasDataToDisplay, setHasDataToDisplay] = useState(true);
    
  const [chartOptions, setOptions] = useState({
    chart: {
      type: 'column',
      style: {
        fontFamily: "\"Open Sans\", Arial, \"Helvetica Neue\", Helvetica, sans-serif",
      },
      height: 450,
      spacingLeft: 0,
      spacingTop: 5
    },
    responsive: {
      rules: [{
        condition: {
          maxWidth: 500,
        },
        chartOptions: {
          xAxis: [{
            labels: {
              style: {
                color: bodyColor,
                fontSize: ".8rem",
                textOverflow: "none"
              }
            }
          }],
        }
      }]
    },
    title: {
      text: "Patient Outcomes",
      widthAdjust: -100, // make sure chart tile and export button don't overlap
      align: "left",
      style: {
        fontFamily: "proxima-nova, sans-serif",
        color: "#1C4D54",
        fontWeight: 'bold',
        fontSize: "1.2rem"
      },
      margin: 25
    },
    subtitle: {
      text: "",
      widthAdjust: -100,
      align: "left",
      style: {
        fontFamily: "\"Open Sans\", Arial, \"Helvetica Neue\", Helvetica, sans-serif",
        fontWeight: 400,
        fontSize: "16px",
        lineHeight: "26px",
        color: "#212529"
      }
    },
    series: Array(categoriesN).fill(0),
    yAxis: [{
      min: 0,
      max: 100,
      title: {
        enabled: false
      },
      labels: {
        format: "{value}%",
        style: {
          color: bodyColor,
          fontSize: ".9rem"
        }
      }
    }],
    xAxis: [{
      labels: {
        style: {
          color: bodyColor,
          fontSize: ".9rem",
          textOverflow: "none"
        }
      }
    },
    ],
    legend: {
      align: 'center',
      verticalAlign: 'bottom',
      layout: 'horizontal',
      itemStyle: {
        color: bodyColor,
        fontSize: ".9rem",
        fontWeight: "normal",
        textOverflow: undefined,
        cursor: 'default'
      },
      itemHoverStyle: {
        color: undefined
      }
    },
    tooltip: {
      formatter: function () {
        return '<b>' + this.series.userOptions.tooltipDisplay + '</b><br/>' +
          this.point.y.toFixed(1) + '%';
      },
      style: {
        fontFamily: "proxima-nova, sans-serif",
        color: "#1C4D54",
        fontSize: "1rem"
      }
    },
    
    navigation: {
      buttonOptions: {
        theme: {
          states: {
            hover: {
              fill: 'none',
              stroke: 'none'
            },
            select: {
              stroke: 'none',
              fill: 'none'
            }
          }
        }
      }
    }
  });

  const handleErrors = (response) => {
    if (!response.ok) {
      console.log("Unable to get data");
      setHasDataError(true);
      setIsLoading(false);
    }
    return response;
  }

  const getVariables = async () => {
    let response = await fetch(`${process.env.REACT_APP_API_HOST}/variables`)
    .then(handleErrors)
    .catch(error => console.log(error));
    await response.json().then(result => {
      let levelsData = {};
      if (result.levels) {
        for (let level of result.levels) {
          let key = level.name;
          let value = level.group;
          if (!levelsData.hasOwnProperty(key)) {
            levelsData[key] = [value];
          } else {
            levelsData[key].push(value);
          }
        }
      }
      // levelsData is object with group as key and name as value(s)
      // keys: blood_type, age_range, meld_range, exception_status, sample_period
      // i.e. levelsData["ABO_Group"] = [A, B, AB, O]

      let transplantCenters = result.transplantCenters;

      let states = new Set();
      if (transplantCenters) {
        for (let entry of transplantCenters) {
          states.add(entry.state);
        }
      }
      let uniqueStates = [...states].sort();

      let marks = [];
      if (levelsData["Time_Frame"]) {
        for (let entry of levelsData["Time_Frame"]) {
          let valueString = entry.match(/\d+/);
          marks.push(
            {
              value: Number(valueString),
              label: valueString,
            }
          );
        }  
      }

      if (!marks.find(x => x.value === 0)) {
        marks.unshift(
          {
            value: 0,
            label: "0",
            enabled: false
          }
        );
      }

      if (uniqueStates && transplantCenters && levelsData && marks && result.lastUpdateMessage) {
        setSelectedMeld(levelsData["MELD_Group"][Math.floor(levelsData["MELD_Group"].length / 2)]);
        setSelectedBloodType(levelsData["ABO_Group"][0]);
        setSelectedAge(levelsData["Age_Group"][0]);
        setSelectedException(levelsData["Exception_Status"][0]);
  
        setStatesData(uniqueStates);
        setAllCenterData(transplantCenters);
        setMeldData(levelsData["MELD_Group"]);
        setBloodTypeData(levelsData["ABO_Group"]);
        setAgeData(levelsData["Age_Group"]);
        setExceptionData(levelsData["Exception_Status"]);
        setCategories(levelsData["Sample_WL_Outcome"]);
        setCategoriesN(levelsData["Sample_WL_Outcome"].length);
		
		    setDateInfo(result.lastUpdateMessage);
      }
      else setHasDataError(true);
      
    });
  }

  const getChartData = async () => {
    setIsLoading(true);
    let body = {
      AgeRange: selectedAge,
      BloodType: selectedBloodType,
      Center: selectedCenter,
      ExceptionStatus: selectedException,
      MELDRange: selectedMeld,
      SamplePeriod: "180 Days"
    };
    let response = await fetch(`${process.env.REACT_APP_API_HOST}/outcomes`, {
      method: 'POST',
      body: JSON.stringify(body),
      headers: { "Content-type": "application/json; charset=UTF-8" },
    })
    .then(handleErrors)
    .catch(error => console.log(error));
    await response.json().then(result => {
      setChartData(result);
      setIsLoading(false);
      //chartData example: 
      //geography: "Region"
      //observationCount: 11
      //percent: 1.0119595216191353
      //sampleWLOutcome: "Condition Improved"
    });
  }

  const getPopupText = async () => {
    await fetch(OptionsPopupText).then((response) => response.text()).then((text) => {
      setOptionsPopupText(text);
    });
    await fetch(GeneralInfoPopupText).then((response) => response.text()).then((text) => {
      setGeneralInfoPopupText(text);
    });
  }

  useEffect(() => {
    getPopupText();
  }, [])

  useEffect(() => {
    getVariables();
  }, []);// eslint-disable-line react-hooks/exhaustive-deps
  
  useEffect(() => {
    getChartData();
  }, [selectedAge, selectedBloodType, selectedCenter, // eslint-disable-line react-hooks/exhaustive-deps
  selectedState, selectedException, selectedMeld])

  useEffect(() => {
    createSeries();
  }, [chartData]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    defineChart();
  }, [series]) // eslint-disable-line react-hooks/exhaustive-deps

  const createSeries = () => {
    let txCenterPercent = Array(categoriesN).fill(0);
    let txCenterN = 0;
    let results = [
      {outcome: "Deceased Donor Transplant", text: language.whatIsRight.pageFive.deceasedDonorSummary},
      {outcome: "Died or Too Sick for Transplant", text: language.whatIsRight.pageFive.diedOrSickSummary},
      {outcome: "Still Waiting", text: language.whatIsRight.pageFive.stillWaitingSummary},
      {outcome: "Living Donor Transplant", text: language.whatIsRight.pageFive.livingDonorSummary},
      {outcome: "Removed: Condition Improved or Other", text: language.whatIsRight.pageFive.removedSummary}
    ]

    let colors = ["#00C9FF","#F77C82","#FBDB68","#8C412B","#182474"]
	  let i = 0;

    if (categories) {
      for (let outcome of categories) {
        for (let data of chartData) {
          if (data.geography === "Tx-Center") {
            if (data.sampleWLOutcome === outcome) {
              results[i].text = results[i].text.replace('0', data.percent.toFixed(0));
              txCenterPercent[i] = {y: data.percent, color: colors[i % colors.length], borderColor: colors[i % colors.length]};
              if (!txCenterN) {
                txCenterN = Number(data.groupTotal);
              }
            } 
          }
        }
        i++;
      }
    }
    setResultsText(results);

    if (!txCenterN && stateIsSelected) {
      setHasDataToDisplay(false);
    }
    else {
      setHasDataToDisplay(true);
    }

    let centerName = selectedCenter ? selectedCenter : "Center";

    setSeries(
      [
        {
          tooltipDisplay: centerName,
          data: txCenterPercent,
          showInLegend: false,
          visible: true,
          events: {
        		legendItemClick: function(x) {
            	x.preventDefault()
            }
          }
        },
      ]
    );
  }

  const defineChart = () => {
    let fullDate = (new Date()).toLocaleDateString("en-US");
    let fileName = "patient-outcomes-at-180-days";

    let subtitleTextBase = `Center: ${selectedCenter}; MELD score range: ${selectedMeld}; Blood type: ${selectedBloodType}; Age range: ${selectedAge}; Exception status: ${selectedException}.`
    let subtitleTextDefault = stateIsSelected ? subtitleTextBase : "";
    let subtitleTextExport = stateIsSelected ? subtitleTextBase + ` (Accessed on ${fullDate}.)` : "";

    let newOptions = {
      ...chartOptions,
      chart: {
        events: {
          beforePrint: function () {
            this.update({subtitle: {text: subtitleTextExport}})
          },
          afterPrint: function () {
            this.update({subtitle: {text: subtitleTextDefault}})
          }
        }
      },
      title: {
        text: `Patient Outcomes at 180 Days`
      },
      subtitle: {
        text: subtitleTextDefault
      },
      series: series,
      navigation: {
        buttonOptions: {
          enabled: true
        }
      },
      xAxis: [{
        categories: categories,
      }],
      exporting: {
        sourceHeight: 600,
        chartOptions: {
          subtitle: {
            text: subtitleTextExport
          }, 
        },
        filename: fileName,
        buttons: {
          contextButton: {
            symbol: `url(${exportIcon})`,
            symbolX: -35,
            symbolY: -20,
            menuItems: ['printChart', 'downloadPNG', 'downloadSVG', 'downloadPDF']
          }
        }
      },
    };

    setOptions(newOptions);
  }

  const handleStateSelect = (selectedState) => {
    setSelectedState(selectedState);
    let centersForThisState = allCenterData.filter(center => center.state === selectedState);
    setStateCenterData(centersForThisState);
    setSelectedCenter(centersForThisState[0].name)
    setStateIsSelected(true);
  }

  const handleCenterSelect = (selectedCenter) => {
    setSelectedCenter(selectedCenter);
  }

  const handleMeldSelect = (selectedMeld) => {
    setSelectedMeld(selectedMeld);
  }

  const handleAgeSelect = (selectedAge) => {
    setSelectedAge(selectedAge);
  }

  const handleBloodTypeSelect = (selectedBloodType) => {
    setSelectedBloodType(selectedBloodType);
  }

  const handleExceptionSelect = (selectedException) => {
    setSelectedException(selectedException);
  }

  const handleGeneralInfoPopupShow = () => {
    setShowGeneralInfoPopup(true);
  }

  const handleGeneralInfoPopupClose = () => {
    setShowGeneralInfoPopup(false);
  }

  const handleOptionsPopupShow = () => {
    setShowOptionsPopup(true);
  }

  const handleOptionsPopupClose = () => {
    setShowOptionsPopup(false);
  }

  const handlePrintButtonClick = () => {
    window.print();
  }

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <Row>
        <Col>

          <Row>
            <Col md="6" sm="12">
              <Row>
                <Col>
                  <div className="bold-instructions heading">
                    {language.whatIsRight.pageFive.leftColumnHeader}
                  </div>
                  <div className="instructions">
                    {language.whatIsRight.pageFive.leftColumnIntro} {" "}
                    <Button className="link" onClick={handleGeneralInfoPopupShow} variant="link">
                      {language.whatIsRight.pageFive.infoModalBtnText}
                    </Button>.
                  </div>
                </Col>
              </Row>

              <Modal show={showGeneralInfoPopup} onHide={handleGeneralInfoPopupClose} dialogClassName="modal-container">
                <Modal.Header closeButton>
                  <Modal.Title className="bold-instructions">
                    {language.whatIsRight.pageFive.infoModalHeader}
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div>
                    <ReactMarkdown className="instructions" children={generalInfoPopupText} />
                  </div>
                </Modal.Body>
              </Modal>

              <Row>
                <Col xs="12">
                  <div key="required-div" className="bold-instructions">
                    {language.whatIsRight.pageFive.requiredHeader}
                  </div>
                  <div key="state-div" className="instructions">
                    {language.whatIsRight.pageFive.stateInstructions}
                    <DropdownButton
                      id="dropdown-menu"
                      align="left"
                      variant="outline-secondary"
                      title={selectedState ? selectedState : language.whatIsRight.pageFive.loadingStatesMessage}
                      onSelect={handleStateSelect}>
                      {statesData && statesData.map((state) => {
                        return (
                          <Dropdown.Item id="dropdown-item" key={state} eventKey={state}>{state}</Dropdown.Item>
                        );
                      })}
                    </DropdownButton>
                  </div>
                </Col>
                <Col xs="12">
                  <div key="center-div" className="instructions">
                    {language.whatIsRight.pageFive.centerInstructions}
                    <DropdownButton
                      id="dropdown-menu"
                      align="left"
                      variant="outline-secondary"
                      title={selectedCenter ? selectedCenter : ""}
                      disabled={!stateIsSelected}
                      onSelect={handleCenterSelect}>
                      {stateCenterData && stateCenterData.map((center) => {
                        return (
                          <Dropdown.Item id="dropdown-item" key={center.name} eventKey={center.name}>{center.name}</Dropdown.Item>
                        );
                      })}
                    </DropdownButton>
                  </div>
                </Col>
                <Col xs="12">
                  <div key="meld-div" className="instructions">
                    {language.whatIsRight.pageFive.meldInstructions}
                    <DropdownButton
                      id="dropdown-menu"
                      align="left"
                      variant="outline-secondary"
                      title={selectedMeld ? selectedMeld : language.whatIsRight.pageFive.loadingMeldMessage}
                      disabled={!stateIsSelected}
                      onSelect={handleMeldSelect}>
                      {meldData && meldData.map((meld) => {
                        return (
                          <Dropdown.Item id="dropdown-item" key={meld} eventKey={meld}>{meld}</Dropdown.Item>
                        );
                      })}
                    </DropdownButton>
                  </div>
                </Col>
              </Row>

              <Row className='spacing'>
                <Col xs="12">
                  <div key="optional-div" className="bold-instructions">
                    {language.whatIsRight.pageFive.optionalHeader}
                  </div>
                  <div key="bloodtype-div" className="instructions">
                    {language.whatIsRight.pageFive.bloodTypeInstructions}
                    <DropdownButton
                      id="dropdown-menu"
                      align="left"
                      variant="outline-secondary"
                      title={selectedBloodType ? selectedBloodType : language.whatIsRight.pageFive.loadingTypesMessage}
                      disabled={!stateIsSelected}
                      onSelect={handleBloodTypeSelect}>
                      {bloodTypeData && bloodTypeData.map((bloodType) => {
                        return (
                          <Dropdown.Item id="dropdown-item" key={bloodType} eventKey={bloodType}>{bloodType}</Dropdown.Item>
                        );
                      })}
                    </DropdownButton>
                  </div>
                </Col>
                <Col xs="12">
                  <div key="age-div" className="instructions">
                    {language.whatIsRight.pageFive.ageInstructions}
                    <DropdownButton
                      id="dropdown-menu"
                      align="left"
                      variant="outline-secondary"
                      title={selectedAge ? selectedAge : language.whatIsRight.pageFive.loadingAgeMessage}
                      disabled={!stateIsSelected}
                      onSelect={handleAgeSelect}>
                      {ageData && ageData.map((age) => {
                        return (
                          <Dropdown.Item id="dropdown-item" key={age} eventKey={age}>{age}</Dropdown.Item>
                        );
                      })}
                    </DropdownButton>
                  </div>
                </Col>
                <Col xs="12">
                  <div key="exception-div" className="instructions" id="instructions">
                    {language.whatIsRight.pageFive.exceptionInstructions}
                    <DropdownButton
                      id="dropdown-menu"
                      align="left"
                      variant="outline-secondary"
                      title={selectedException ? selectedException : language.whatIsRight.pageFive.loadingExceptionMessage}
                      disabled={!stateIsSelected}
                      onSelect={handleExceptionSelect}>
                      {exceptionData && exceptionData.map((option) => {
                        return (
                          <Dropdown.Item id="dropdown-item" key={option} eventKey={option}>{option}</Dropdown.Item>
                        );
                      })}
                    </DropdownButton>
                  </div>
                </Col>
              </Row>
            </Col>

            <Col md="6" sm="12">
              <Row>
                <Col>
                  <ErrorBoundary FallbackComponent={ErrorFallback}>
                    <div key="div-chart" id="chart">
                      <Loading loading={isLoading} />
                      <DataError error={hasDataError} />
                      <div className="instructions no-data-message" style={hasDataToDisplay || isLoading ? {display: "none"} : null}>
                        {hasDataToDisplay ? null : language.whatIsRight.pageFive.noDataToDisplayMessage}
                      </div>
                      <HighchartsReact highcharts={Highcharts} options={chartOptions} />
                    </div>
                  </ErrorBoundary>
                </Col>
              </Row>

              <Row>
                <Col className='spacing'>
                  <div className='bold-instructions'>
                    {language.whatIsRight.pageFive.chartSummaryHeader}
                  </div>
                  {resultsText.map((result, index) => 
                    <div key={index} className='instructions results'>{result.text}</div>
                  )}
                </Col>
              </Row>
            </Col>
          </Row>

          <Row>
            <Col className="center spacing">
              <Button className="btn btn-clear-blue bottom-btn" onClick={handleOptionsPopupShow}>
                {language.whatIsRight.pageFive.optionsButtonText}
              </Button>{' '}
              <Button className="btn btn-red bottom-btn" onClick={handlePrintButtonClick}>
                {language.whatIsRight.pageFive.printButtonText}
              </Button>{' '}
            </Col>
          </Row>

          <Modal show={showOptionsPopup} 
            onHide={handleOptionsPopupClose} 
            dialogClassName="modal-container"
            scrollable={true}>
            <Modal.Header closeButton>
              <Modal.Title className="bold-instructions">
                {language.whatIsRight.pageFive.optionsButtonText}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <ReactMarkdown className="instructions" children={optionsPopupText} />
              </div>
            </Modal.Body>
          </Modal>
          
          <Row>
            <Col>
            <div key="update-message" className="instructions spacing">
              {dateInfo}
            </div>
            </Col>
          </Row>

        </Col>
      </Row>
    </ErrorBoundary>
  )
}

export default Calculator;
